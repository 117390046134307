import React from "react"
import PropTypes from "prop-types"
import ExcluPage from "../containers/excluPage"
import cristalgif from "../images/cristal.gif"

const CadreEntreDeuxVerres = (props) => {
  return (
    <>
      <ExcluPage
        title="Cadre entre deux verres"
        description={
          <>
            Ce cadre entre 2 verres de conception exclusive vous assurera une mise en valeur très originale de votre document : photo, lithographie, poster,
            affiche...
            <br />
            Le montage entre deux verres consiste à glisser votre sujet entre deux plaques de verres plus grandes maintenues par le cadre entre 2 verres.
            <br />
            La transparence du verre entre votre sujet et le cadre donnera l’impression que le sujet flotte dans l’espace. Effet garanti ! Le montage de votre
            sujet dans le cadre se fait très facilement grâce au système mis en place par nos soins.
          </>
        }
        link="/cadre/Cadre-entre-deux-verres-Entre-2-verres-Noir-1889/"
        img={cristalgif}
        location={props.location}
      />
    </>
  )
}

CadreEntreDeuxVerres.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default CadreEntreDeuxVerres
