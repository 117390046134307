import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby-plugin-spinner"

export const ExcluPage = ({ title, description, link, img, ...props }) => {
  return (
    <>
      <SEO title={title} description={description} canonical={`${process.env.GATSBY_URL_ACTUEL}${props.location.pathname}`} />
      <Layout banner={false} location={props.location}>
        <div className="container">
          <h1>{title}</h1>
          <div className="columns is-centered">
            <div className="column">
              {description}
              <br />
              <Link to={link}>
                <button className="button is-large is-primary mt-5" data-cy="decouvrir-exclu">Découvrir</button>
              </Link>
            </div>
            <div className="column is-narrow">
              <img src={img} alt={title} />
              <br />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

ExcluPage.propTypes = { title: PropTypes.string, description: PropTypes.string, link: PropTypes.string, img: PropTypes.string, location: PropTypes.string }

export default ExcluPage
